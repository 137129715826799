import React, { Component } from 'react'
import Cookies from 'cookies-js'
import { withRouter } from 'next/router'
import { loadStripe } from '@stripe/stripe-js/pure'
import { connect } from 'react-redux'
import { FaCheck } from 'react-icons/fa'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import { STRIPE_KEY } from '../config'
import RadioTabGroup from '../util/RadioTabGroup'
import { apiFetch, toast, log } from '../util'
import Button from '../components/Button'

const PRICING_TIER_REDIRECT_COOKIE = 'pricing_redirect_tier'

const AwsLogo = dynamic(() => import('../assets/awsLogo'))
const AzureLogo = dynamic(() => import('../assets/azureLogo'))
const DockerLogo = dynamic(() => import('../assets/dockerLogo'))
const GcpLogo = dynamic(() => import('../assets/gcpLogo'))
const RaspberryPiLogo = dynamic(() => import('../assets/raspberryPiLogo'))

const pricing = {
  month: { standard: 7, business: 49, pro: 25 },
  year: { standard: 70, business: 500, pro: 250 },
}

class Pricing extends Component {
  state = {
    editingNamespace: false,
    pricingPlan: 'month',
  }

  componentDidMount = () => {
    document.documentElement.setAttribute('data-theme', 'pricing')
    const tier = Cookies.get(PRICING_TIER_REDIRECT_COOKIE)
    if (tier && this.props.profile) {
      // unset cookie and redirect
      Cookies.set(PRICING_TIER_REDIRECT_COOKIE, '', { expires: new Date() })
      this.fetchStripeSession(tier)
    }
  }

  gitHubLoginThenStripe = tier => {
    // Valid for session only
    Cookies.set(PRICING_TIER_REDIRECT_COOKIE, tier)
    log.debug('gitHubLoginThenStripe: redirecting to /login')
    window.location = '/login'
  }

  fetchStripeSession = async tier => {
    if (!this.props.profile) return this.gitHubLoginThenStripe(tier)
    const { status, json } = await apiFetch('/stripe-session', {
      method: 'POST',
      body: { tier, pricingPlan: this.state.pricingPlan },
    })
    if (json.error) return toast({ msg: json.error })
    if (status !== 200) return toast({ msg: 'Error redirecting to billing portal' })
    const stripe = await loadStripe(STRIPE_KEY)
    await stripe.redirectToCheckout({
      sessionId: json.id,
    })
  }

  render() {
    let proView = false
    const tier = this.props?.profile?.tier || ''
    if (typeof window !== 'undefined') {
      const args = window.location.search.slice(1).split('=')
      if (args[0] === 'pro') {
        proView = true
      }
    }

    return (
      <div className="Pricing">
        <h1>Pricing</h1>
        <div className="pricing-subtitle">Home-Hosting</div>
        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <RadioTabGroup
              className="pricing-plan"
              options={['Monthly', 'Yearly']}
              value={this.state.pricingPlan === 'year' ? 'Yearly' : 'Monthly'}
              onChange={_e =>
                this.setState({
                  pricingPlan: this.state.pricingPlan === 'year' ? 'month' : 'year',
                })
              }
            />
          </div>
          <div style={{ marginTop: 10 }}>20% off with yearly pricing!</div>
        </div>
        <div className="tiers">
          <div className="row">
            {!proView ? (
              <div className="tier">
                <h3>Free</h3>
                <div className="price">
                  $<h4>0</h4>
                  <h5>
                    <div>per cluster</div>
                    <div>per {this.state.pricingPlan}</div>
                  </h5>
                </div>
                <ul>
                  <li>
                    <FaCheck />
                    Free Dynamic DNS
                  </li>
                  <li>
                    <FaCheck />
                    Limited proxy bandwidth
                  </li>
                  <li>
                    <FaCheck />
                    Limited Backup tools
                  </li>
                  <li>
                    <FaCheck />
                    All other features unlocked
                  </li>
                </ul>
                {!tier || tier === 'free' ? <Button href="/login">Sign Up</Button> : null}
              </div>
            ) : null}
            {proView ? (
              <div className="tier highlight">
                <h3>Pro</h3>
                <div className="price">
                  $<h4>{pricing[this.state.pricingPlan].pro}</h4>
                  <h5>
                    <div>per cluster</div>
                    <div>per {this.state.pricingPlan}</div>
                  </h5>
                </div>
                <ul>
                  <li>
                    <FaCheck />
                    5TB proxy traffic
                  </li>
                  <li>
                    <FaCheck />
                    Unlimited backups
                  </li>
                  <li>
                    <FaCheck />
                    Unlimited users
                  </li>
                  <li>
                    <FaCheck />
                    Priority Support
                  </li>
                </ul>
                {!tier ? (
                  <Button
                    onClick={async () => {
                      await this.fetchStripeSession('pro')
                    }}
                  >
                    Start Now
                  </Button>
                ) : tier === 'free' ? (
                  <Button
                    onClick={async () => {
                      await this.fetchStripeSession('pro')
                    }}
                  >
                    Upgrade
                  </Button>
                ) : tier === 'pro' ? (
                  <h2>Current Plan</h2>
                ) : null}
              </div>
            ) : (
              <div className="tier highlight">
                <h3>Standard</h3>
                <div className="price">
                  $<h4>{pricing[this.state.pricingPlan].standard}</h4>
                  <h5>
                    <div>per cluster</div>
                    <div>per {this.state.pricingPlan}</div>
                  </h5>
                </div>
                <ul>
                  <li>
                    <FaCheck />
                    Custom Domains
                  </li>
                  <li>
                    <FaCheck />
                    Encrypted Backups
                  </li>
                  <li>
                    <FaCheck />
                    25GB proxy traffic
                  </li>
                  <li>
                    <FaCheck />
                    Tech Support
                  </li>
                  <li>
                    <FaCheck />
                    One plan, all the features
                  </li>
                </ul>
                {!tier ? (
                  <Button
                    onClick={async () => {
                      await this.fetchStripeSession('standard')
                    }}
                  >
                    Start Now
                  </Button>
                ) : tier === 'free' ? (
                  <Button
                    onClick={async () => {
                      await this.fetchStripeSession('standard')
                    }}
                  >
                    Upgrade
                  </Button>
                ) : tier === 'standard' ? (
                  <h2>Current Plan</h2>
                ) : null}
              </div>
            )}
            <div className="tier">
              <h3>Business</h3>
              <div className="starting-at">starting at</div>
              <div className="price">
                $<h4>{pricing[this.state.pricingPlan].business}</h4>
                <h5>
                  <div>per cluster</div>
                  <div>per {this.state.pricingPlan}</div>
                </h5>
              </div>
              <ul>
                <li title="No sharing!">
                  <FaCheck />
                  Dedicated Gateways
                </li>
                <li title="We're here to help!">
                  <FaCheck />
                  Solutions Architect
                </li>
                <li title="We off custom, hands-on training to your staff!">
                  <FaCheck />
                  <Link prefetch={false} href="/training">
                    On-location Training
                  </Link>
                </li>
                <li title="Looking to do a larger installation">
                  <FaCheck />
                  Custom Contracts
                </li>
              </ul>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href={
                  `mailto:sales@kubesail.com?subject=KubeSail%20Enterprise&body=` +
                  encodeURIComponent(
                    "Hello\n\nI'm interested in KubeSail Enterprise - let's set up a meeting and get started!\n"
                  )
                }
                style={{ textDecoration: 'none' }}
              >
                Contact Sales
              </Button>
            </div>
          </div>
        </div>
        <h1>KubeSail for Business</h1>
        <div className="resource-pricing">
          <div style={{ marginTop: 40 }}>
            We ship a tiny, powerful, secure server ready to be plugged-in and used directly to your
            offices or edge locations. From Calendly replacements like{' '}
            <a
              rel="noreferrer nofollow"
              target="_blank"
              title="Cal.com is a self-hosted alternative to Calendly that can be hosted directly from your office!"
              href="https://kubesail.com/template/erulabs/Cal.com"
            >
              Cal.com
            </a>{' '}
            to Slack alternatives like{' '}
            <a
              rel="noreferrer nofollow"
              target="_blank"
              title="Rocket.chat replaces expensive services like Slack with a fully self-hosted alternative"
              href="https://kubesail.com/template/erulabs/rocket-chat"
            >
              Rocket.chat
            </a>
            , we help upgrade your businesses software while slashing expensive subscriptions!
            <div>
              <Button
                className="inline light"
                target="_blank"
                rel="noopener noreferrer"
                href={
                  `mailto:sales@kubesail.com?subject=KubeSail%20for%20Business&body=` +
                  encodeURIComponent(
                    "Hello\n\nI'm interested in KubeSail for Business - let's set up a meeting and get started!\n"
                  )
                }
                style={{ textDecoration: 'none' }}
              >
                Contact Sales
              </Button>
            </div>
          </div>
        </div>
        <div className="byoc">
          <div className="byoc-types">
            <div className="byoc-type">
              <div className="icons">
                <AwsLogo />
                <GcpLogo />
                <AzureLogo />
              </div>
              <ul>
                <li>
                  <FaCheck />
                  <div>Deploy apps on any cloud</div>
                </li>
              </ul>
            </div>
            <div className="byoc-type">
              <div className="icons">
                <RaspberryPiLogo />
                <DockerLogo />
              </div>
              <ul>
                <li>
                  <FaCheck />
                  <div>Ship plug-n-play hardware to the edge!</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(({ profile }) => {
  return { profile }
})(withRouter(Pricing))
