import React, { Component } from 'react'
import Link from 'next/link'
import { FaCheck, FaChevronDown } from 'react-icons/fa'

export default class Button extends Component {
  state = {
    split: 0,
    showingSplitMenu: false,
  }

  componentDidMount = () => {
    document.body.addEventListener('click', this.handleBodyClick)
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('click', this.handleBodyClick)
  }

  handleBodyClick = e => {
    if (this.dropdown && this.dropdown.contains(e.target)) {
      return
    }

    this.setState({ showingSplitMenu: false })
  }

  render() {
    let {
      className = '',
      disabled,
      children,
      href,
      as,
      type,
      title,
      onClick,
      tag,
      marginRight,
      count,
      prefetch,
      splits,
      style = {},
      target,
      rel,
    } = this.props
    const { split, showingSplitMenu } = this.state
    if (!tag && !href) {
      tag = 'div'
    }
    if (!tag || tag === 'Link' || tag === 'Link') {
      tag = Link
      prefetch = false
    }
    if (tag === Link && !href) throw new Error('Buttons require hrefs')
    if (disabled) className += ' disabled'

    children = children || splits[split].name
    const wrapperClass = [
      'Button translate',
      typeof count === 'number' ? 'has-count' : 'Button-wrap',
      type,
      className,
    ]
      .filter(n => n)
      .join(' ')

    const button = React.createElement(
      tag,
      {
        className: tag === 'button' ? wrapperClass : 'button-wrapper',
        href,
        as,
        type,
        title,
        style,
        target,
        prefetch,
        rel,
      },
      <div onClick={onClick} className={tag === 'button' ? undefined : wrapperClass}>
        {children}
      </div>
    )

    if (typeof count === 'number') {
      return (
        <div className={`Button-wrap ${className}`} style={{ marginRight }}>
          {button}
          <div className="Button-count">{count}</div>
        </div>
      )
    } else if (splits) {
      return (
        <div className={`Button-wrap ${className}`} style={{ marginRight }}>
          {button}
          <div
            className="Button-dropdown"
            onClick={e => {
              e.stopPropagation()
              this.setState({ showingSplitMenu: !showingSplitMenu })
            }}
          >
            <FaChevronDown className="fa-xs" />
          </div>
          {showingSplitMenu && (
            <div className="splits" ref={n => (this.dropdown = n)}>
              {splits.map(({ name, description, href, selected }, index) => (
                <a key={name} className="split" href={href} style={{ textDecoration: 'none' }}>
                  <div className="split-icon">{selected ? <FaCheck /> : null}</div>
                  <div className="split-details">
                    <h3>{name}</h3>
                    <p>{description}</p>
                  </div>
                </a>
              ))}
            </div>
          )}
        </div>
      )
    } else {
      return button
    }
  }
}
